import {
  takeLatest,
  call,
  put,
  all,
  select,
  takeEvery,
} from 'redux-saga/effects';

import api from '../../../services/api';
import i18n from '../../../translations/i18n';
import { updateCarouselCardImageUrl } from './utils';
import { isRequestCanceledError } from '~/utils/apiErrors';

import { toast } from '~/components/CustomToast';

import { commonLoadingStart, commonLoadingFinish } from '../common/actions';
import { updateBotLastUpdate } from '../bots/actions';
import * as actions from './actions';
import * as actionsConnections from '../connectors/actions';
import * as actionsBot from '../bots/actions';
import types from './types';
import { invalidateCache } from '../../../services/cache';

const arrayBlockMedia = [
  'image',
  'dynamicMedia',
  'audio',
  'collect',
  'video',
  'document',
];

export function* fetchItems({ payload }) {
  try {
    const { botId } = payload;
    yield put(commonLoadingStart());
    const { data } = yield call(api.get, `/v3/bots/${botId}/items`);
    yield put(actions.fetchItemsSuccess({ items: data?.items || [] }));
    yield put(actionsBot.fetchBotSuccess({ bot: data?.bot }));
  } catch (error) {
    if (!isRequestCanceledError(error)) {
      toast.error(i18n.t('error.oops'), i18n.t('error.error_items_list'));
    }
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* fetchSubflowItems({ payload }) {
  try {
    const { botId, subflowId } = payload;

    yield put(commonLoadingStart());
    const { data } = yield call(
      api.get,
      `/v3/bots/${botId}/items-subflow/${subflowId}`
    );
    yield put(
      actions.fetchSubflowItemsSuccess({
        items: data?.items || [],
        subflowPath: data?.path || [],
      })
    );
  } catch (error) {
    if (!isRequestCanceledError(error)) {
      toast.error(i18n.t('error.oops'), i18n.t('error.error_group_items_list'));
    }
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* fetchAllItems({ payload }) {
  try {
    yield put(commonLoadingStart());

    const { botId, callback } = payload;
    callback?.(true);

    const response = yield call(api.get, `/v3/bots/${botId}/all-items`);

    yield put(
      actions.fetchAllItemsSuccess({
        allItems: response.data,
      })
    );
    callback?.(false);
  } catch (err) {
    if (!isRequestCanceledError(err)) {
      toast.error(i18n.t('error.oops'), i18n.t('error.error_items_list'));
    }
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* fetchResumedAllItems({ payload }) {
  try {
    yield put(commonLoadingStart());

    const { botId, callback } = payload;
    callback?.(true);

    const url = `/v3/bots/${botId}/resumed-all-items`;

    const response = yield call(api.get, url);

    yield put(actions.fetchResumedAllItemsSuccess(response.data));
    callback?.(false);
  } catch (err) {
    if (!isRequestCanceledError(err)) {
      toast.error(i18n.t('error.oops'), i18n.t('error.error_items_list'));
    }
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* fetchItemLastVersion({ payload }) {
  try {
    yield put(commonLoadingStart());

    const { botId, originalId } = payload;

    const response = yield call(
      api.get,
      `/v3/bots/${botId}/item-last-version/${originalId}`
    );

    yield put(
      actions.fetchItemSuccessLastVersion({
        itemLastVersion: response.data[0],
      })
    );
  } catch (err) {
    yield put(
      actions.fetchItemSuccessLastVersion({
        itemLastVersion: [],
      })
    );
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* createItem({ payload }) {
  try {
    if (payload.type !== 'SuggestionConnector') {
      const { botId } = payload;
      const { bot } = yield select((state) => state.bots);
      const url = `/v3/bots/${botId}/items`;
      const hasIaOnAllBlocks = !!bot?.iaStatus;
      const { data } = yield call(
        api.post,
        url,
        {
          ...payload,
          useIA: hasIaOnAllBlocks,
        },
        {
          clearCacheEntry: [
            '/items',
            '/resumed-all-items',
            '/items-subflow',
            url,
          ],
        }
      );
      yield put(actions.createItemSuccess({ payload: data }));
      yield put(updateBotLastUpdate({ botId }));
    } else {
      yield put(actions.createItemSuccess({ payload }));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* ivrCloneItemGroup({ payload }) {
  try {
    const { item, callback } = payload;

    const { data } = yield call(
      api.post,
      `/v3/bots/${item.botId}/items/${item._id}/ivr-clone-group`,
      item
    );
    yield put(actions.ivrCloneItemGroupSuccess({ payload: data }));
    yield put(updateBotLastUpdate({ botId: item.botId }));

    callback?.({ item: data });
  } catch (err) {
    toast.error(i18n.t('error.oops'), i18n.t('error.error_clone_item'));
    yield put(actions.ivrCloneItemGroupFailure());
  }
}

export function* ivrCloneItems({ payload }) {
  try {
    const { botId, subflowFor, items, connectorIds, callback } = payload;

    const { data } = yield call(
      api.post,
      `/v3/bots/${botId}/items/ivr-clone-items`,
      { subflowFor, items, connectorIds }
    );

    yield put(actions.ivrCloneItemsSuccess(data.items));

    const connectors = yield select((state) => state.connectors.connectors);
    yield put(
      actionsConnections.fetchConnectorsSuccess({
        connectors: [...(connectors || []), ...(data?.connectors || [])],
      })
    );

    yield put(updateBotLastUpdate({ botId }));

    callback?.({ items: data.items, connectors: data?.connectors });
  } catch (err) {
    console.log(err);
    toast.error(i18n.t('error.oops'), i18n.t('error.error_clone_item'));
    yield put(actions.ivrCloneItemsFailure());
  }
}

export function* updateItem({ payload }) {
  const { item, callback } = payload;
  const { botId, _id } = item;

  try {
    const items = yield select((state) => state.items.items);

    const url = `/v3/bots/${botId}/items/${_id}`;

    const { data } = yield call(api.put, url, item, {
      clearCacheEntry: ['/resumed-all-items', '/items-subflow', '/items'],
    });

    const { itemDTO, countUpdates } = data;

    const itemIndex = items.findIndex((item) => item._id === _id);

    const newItem = {
      ...itemDTO,
      thumbnailUrl: items[itemIndex]?.thumbnailUrl,
      fileUrl: items[itemIndex]?.fileUrl,
    };

    yield put(updateBotLastUpdate({ botId, qtdUpdates: countUpdates }));

    yield put(actions.updateItemSuccess({ index: itemIndex, item: newItem }));

    if (callback) {
      callback({ success: true });
    }
  } catch (error) {
    const statusCode = error.response?.status;
    if (statusCode !== 404 && !isRequestCanceledError(error)) {
      toast.error(i18n.t('error.oops'), i18n.t('error.error_items_update'));
    }
    if (callback) {
      callback({ success: false, error });
    }
  }
}

export function* updateItems({ payload }) {
  const { items, callback } = payload;
  try {
    const botId = items[0].botId;

    const url = `/v3/bots/${botId}/items/`;

    const { data } = yield call(api.put, url, items, {
      clearCacheEntry: [
        '/items',
        '/resumed-all-items',
        '/all-items',
        '/items-subflow',
      ],
    });
    const { countItemsUpdate } = data;

    yield put(updateBotLastUpdate({ botId, qtdUpdates: countItemsUpdate }));
    yield put(actions.updateItemsSuccess(items));

    if (callback) {
      callback({ success: true });
    }
  } catch (error) {
    toast.error(i18n.t('error.oops'), i18n.t('error.error_items_update'));

    if (callback) {
      callback({ success: false, error });
    }
  }
}

export function* deleteItem({ payload }) {
  try {
    const { botId, items, callback } = payload;

    const itemIds = items
      .map((el) => {
        return !el.isTemp ? el.id : false;
      })
      .filter((el) => el !== false);

    if (itemIds.length > 0) {
      yield invalidateCache([
        '/items',
        '/resumed-all-items',
        '/all-items',
        '/items-subflow',
      ]);

      const url = `/v3/bots/${botId}/items`;
      yield call(api.delete, url, {
        data: { itemIds },
      });

      yield put(updateBotLastUpdate({ botId }));
      yield put(actions.deleteItemSuccess({ itemIds }));
    }

    callback?.();
  } catch (err) {
    toast.error(i18n.t('error.oops'), i18n.t('error.error_items_remove'));
  }
}

export function* uploadCardImage({ payload }) {
  try {
    yield put(commonLoadingStart());
    const { file, item, cardId, callback } = payload;
    const { botId, _id: itemId } = item;

    const formData = new FormData();
    formData.append('file', file);

    const response = yield call(
      api.put,
      `/v3/bots/${botId}/items/${itemId}/cards/${cardId}/upload-image`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        clearCacheEntry: ['/items', '/items-subflow'],
      }
    );

    const updatedCards = updateCarouselCardImageUrl({
      oldItem: item,
      newItem: response.data,
      cardId,
    });

    const items = yield select((state) => state.items.items);
    const itemIndex = items.findIndex((i) => i._id === item?._id);
    const newItem = {
      ...items[itemIndex],
      carousel: { cards: updatedCards },
    };

    callback?.({ updatedCards, itemId });
    yield put(
      actions.updateItemSuccess({
        index: itemIndex,
        item: newItem,
      })
    );
    yield put(updateBotLastUpdate({ botId }));
  } catch (err) {
    toast.error(i18n.t('error.oops'), i18n.t('error.error_items_update'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* uploadItemFile({ payload }) {
  yield put(commonLoadingStart());
  try {
    const { botId, file, item, callback } = payload;
    const formData = new FormData();
    formData.append('file', file);

    const { data } = yield call(
      api.put,
      `/v3/bots/${botId}/items/${item._id}/upload`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        clearCacheEntry: ['/items', '/items-subflow'],
      }
    );

    let parsedData = null;
    if (typeof data === 'string') {
      parsedData = JSON.parse(data.split('|')[0]);
    } else {
      parsedData = data;
    }

    const items = yield select((state) => state.items.items);
    const itemIndex = items.findIndex((i) => i._id === item?._id);
    const newItem = {
      ...items[itemIndex],
      thumbnailUrl: parsedData?.thumbnailUrl,
      fileUrl: parsedData?.fileUrl,
    };

    if (data.error) {
      if (data.message) {
        toast.error(i18n.t('error.oops'), `${data.message}`);
      } else {
        toast.error(i18n.t('error.oops'), i18n.t('error.error_upload'));
      }
    } else {
      yield put(
        actions.updateItemSuccess({
          index: itemIndex,
          item: newItem,
        })
      );

      if (arrayBlockMedia.includes(newItem.type?.toLowerCase())) {
        callback?.({
          fileUrl: parsedData?.fileUrl,
          thumbnailUrl: parsedData?.thumbnailUrl,
          itemId: item?._id,
        });
      }
    }
    yield put(updateBotLastUpdate({ botId }));
  } catch (error) {
    if (error.response?.data.isSizeExceed === true) {
      return toast.error(i18n.t('error.oops'), i18n.t('builder.large_file'));
    }
    toast.error(i18n.t('error.oops'), i18n.t('builder.error_edit_upload'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* removeItemFile({ payload }) {
  try {
    const { item, botId, callback } = payload;
    const { _id } = item;
    const { data } = yield call(
      api.put,
      `/v3/bots/${botId}/items/${item?._id}/remove`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
        },
        clearCacheEntry: ['/items', '/items-subflow'],
      }
    );
    const items = yield select((state) => state.items.items);

    if (data.error) {
      toast.error(i18n.t('error.oops'), i18n.t('builder.error_remove_upload'));
    } else {
      callback({
        fileUrl: '',
        thumbnailUrl: '',
        itemId: item._id,
      });
    }
    const itemIndex = items.findIndex((item) => item._id === _id);
    yield put(
      actions.updateItemSuccess({
        index: itemIndex,
        item: { ...data, fileUrl: '', thumbnailUrl: '' },
      })
    );
    yield put(updateBotLastUpdate({ botId }));
  } catch (error) {
    toast.error(i18n.t('error.oops'), i18n.t('builder.error_edit_upload'));
  }
}

export function* downloadItemFile(action) {
  const { url, item, botId, callback } = action.payload;
  yield put(commonLoadingStart());

  try {
    const endpoint = `/v3/bots/${botId}/items/${item._id}/download`;
    const payload = { url };

    const { data } = yield call(api.put, endpoint, payload);

    if (data.error) {
      return toast.error(
        i18n.t('error.oops'),
        i18n.t('builder.invalid_format')
      );
    }

    let response = null;
    if (typeof data === 'string') {
      response = JSON.parse(data.split('|')[0]);
    } else {
      response = data;
    }

    const items = yield select((state) => state.items.items);
    const itemIndex = items.findIndex((i) => i._id === item?._id);
    const updatedItem = {
      ...items[itemIndex],
      thumbnailUrl: response?.thumbnailUrl,
      fileUrl: response?.fileUrl,
    };

    if (updatedItem.error) {
      return toast.error(
        i18n.t('error.oops'),
        i18n.t('builder.invalid_format')
      );
    }

    yield put(
      actions.updateItemSuccess({ index: itemIndex, item: updatedItem })
    );

    if (arrayBlockMedia.includes(updatedItem.type?.toLowerCase())) {
      callback?.({
        index: itemIndex,
        item: updatedItem,
      });
    }
  } catch (error) {
    if (error.response?.data.isInvalidExtension === true) {
      return toast.error(
        i18n.t('error.oops'),
        i18n.t('builder.invalid_extension')
      );
    }

    if (error.response?.data.isSizeExceed === true) {
      return toast.error(i18n.t('error.oops'), i18n.t('builder.large_file'));
    }

    toast.error(i18n.t('error.oops'), i18n.t('builder.error_edit_upload'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* returnItem({ payload }) {
  yield put(
    actions.returnItemSuccess({
      returned: {
        id: payload.returnItemId,
        positionOnScreen: payload.returnItemPosition,
        url: payload.url,
        isGroup: payload.isGroup,
      },
    })
  );
}

export function* createGptResponses({ payload }) {
  try {
    yield put(commonLoadingStart());
    const { item, bot, example, publish = false } = payload.payload;

    let newItem = {};

    if (!publish) {
      const { data } = yield call(
        api.post,
        `/v3/bots/chatgpt/${bot._id}`,
        {
          data: { item, bot },
        },
        { clearCacheEntry: ['/items', '/items-subflow'] }
      );

      newItem = {
        ...item,
        chatgpt: {
          ...item.chatgpt,
          responses: data.data,
          lastUpdate: new Date(),
        },
      };
    }

    if (example) {
      yield put(actions.createItemExampleSuccess({ item: newItem }));
    } else {
      const items = yield select((state) => state.items.items);
      const itemIndex = items.findIndex((_item) =>
        _item._id === publish ? item._id : newItem._id
      );

      yield call(
        api.put,
        `/v3/bots/${bot._id}/items/${item._id}`,
        publish ? item : newItem,
        { clearCacheEntry: ['/items', '/resumed-all-items', '/items-subflow'] }
      );

      yield put(
        actions.updateItemSuccess({
          index: itemIndex,
          item: publish ? item : newItem,
        })
      );

      if (item?.version === 0) {
        yield put(updateBotLastUpdate({ botId: bot._id }));
      }

      toast.success(
        i18n.t(
          'bots.builder.components.menu_settings.block.types.chat_gpt.success'
        )
      );
    }
  } catch (err) {
    toast.error(
      i18n.t('error.oops'),
      i18n.t('bots.builder.components.menu_settings.block.types.chat_gpt.error')
    );
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* fetchItemsSummary({ payload }) {
  try {
    const { botId } = payload;
    const { data } = yield call(api.get, `/v3/bots/${botId}/items-summary`);
    yield put(actions.fetchItemsSummarySuccess(data));
  } catch (error) {
    yield put(actions.fetchItemsSummaryFailure());
    if (!isRequestCanceledError(error)) {
      toast.error(i18n.t('error.oops'), i18n.t('error.error_items_list'));
    }
  }
}

export default all([
  takeLatest(types.FETCH_ITEMS, fetchItems),
  takeLatest(types.FETCH_ALL_ITEMS, fetchAllItems),
  takeLatest(types.FETCH_RESUMED_ALL_ITEMS, fetchResumedAllItems),
  takeLatest(types.FETCH_ITEM_LAST_VERSION, fetchItemLastVersion),
  takeLatest(types.FETCH_SUBFLOW_ITEMS, fetchSubflowItems),
  takeEvery(types.UPDATE_ITEM, updateItem),
  takeLatest(types.CREATE_ITEM, createItem),
  takeLatest(types.IVR_CLONE_ITEM_GROUP, ivrCloneItemGroup),
  takeLatest(types.IVR_CLONE_ITEMS, ivrCloneItems),
  takeLatest(types.DELETE_ITEM, deleteItem),
  takeLatest(types.UPLOAD_CARD_IMAGE, uploadCardImage),
  takeLatest(types.UPLOAD_ITEM_FILE, uploadItemFile),
  takeLatest(types.REMOVE_ITEM_FILE, removeItemFile),
  takeLatest(types.DOWNLOAD_ITEM_FILE, downloadItemFile),
  takeLatest(types.UPDATE_ITEMS, updateItems),
  takeLatest(types.RETURN_ITEM, returnItem),
  takeLatest(types.CREATE_CHATGPT_RESPONSES, createGptResponses),
  takeLatest(types.FETCH_ITEMS_SUMMARY, fetchItemsSummary),
]);
